import { lazy } from "react";

const BaseRoutes = [
  {
    path: "/*",
    component: lazy(() => import("./AuthRouter")),
    useAuth: false,
    exact: true,
  },

  {
    path: "*",
    component: lazy(() => import("../modules/NotFound")),
    useAuth: false,
    exact: true,
  },

  {
    path: "/*",
    component: lazy(() => import("../modules/NotFound")),
    useAuth: false,
    exact: true,
  },
];

export default BaseRoutes;
