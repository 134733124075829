import BaseRoutes from "./base";
import { Fragment, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import NotFound from "../../../src/modules/NotFound";
import { CircleLoading } from "../components/loading";
import React from "react";
import NotFound from "../modules/NotFound";
import OfflineNotifier from "./OfflinePageFunc";

interface RouteConfig {
  path: string;
  exact: boolean;
  component: React.ComponentType;
  useAuth: boolean;
  allowedRoles?: string[];
}

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      <OfflineNotifier />
      <Suspense fallback={<CircleLoading />}>
        <Routes>
          {(BaseRoutes as RouteConfig[]).map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Fragment>
                  <Suspense fallback={<CircleLoading />}>
                    {/* {route.useAuth ? (
                      <AuthGuard allowedRoles={route.allowedRoles}>
                        <route.component />
                      </AuthGuard>
                    ) : (
                      <route.component />
                    )} */}
                    <route.component />
                  </Suspense>
                </Fragment>
              }
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default RoutesWrapper;
