import React, { useState, useEffect } from "react";
import { OfflinePage } from "../modules/Pages/OfflinePage";

const OfflineNotifier = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  // if (!isOnline) {
  //   return <Navigate to="/offline" state={{ from: location }} replace />;
  // }

  if (!isOnline) {
    return (
      // <>

      //   <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      //     <div className="text-white text-center">
      //       <p>You are offline. Please check your internet connection.</p>
      //     </div>
      //   </div>
      // </>
      <OfflinePage />
    );
  }

  return null;
};

export default OfflineNotifier;
